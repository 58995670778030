<template>
  <div class="xx_info">
    <navig :w="w" :only="only" v-if="equipment == 'pc'" />
    <web-nav v-if="equipment == 'web'" />
    <div class="pc">
      <div class="header">
        <img src="~img/bg1.png" class="bg-img" />
        <img src="~img/tit1.png" class="logo-img" />
        <img src="~img/tit2.png" class="logo-img2" />
        <h5>质量和信息安全保障</h5>
        <div class="heng"></div>
        <p class="title">
          安谋科技于2021年首次荣获ISO/IEC 27001:2013信息安全管理体系和ISO
          9001:2015质量管理体系认证证书，成功将位于深圳、上海、北京及成都的所有部门纳入统一的质量与信息安全管理体系之中，实现了高效管理。
        </p>
        <p class="title" style="margin-top: .4rem;">
          安谋科技于2024年荣获升级版ISO/IEC 27001:2022信息安全管理体系和ISO
          9001:2015质量管理体系再认证证书，持续强化信息安全防护，并不断提升产品与服务质量。
        </p>
        <div class="xx_one">
          <img src="~/img/9001.png" />
          <img src="~/img/27001.png" />
        </div>
      </div>
    </div>
    <div class="footer">
      <web-footer v-if="equipment == 'web'" />
    </div>
    <pc-footer :showFooter="showFooter" v-if="equipment == 'pc'" />
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import pcFooter from '@/components/pcFooter.vue' // pc 底部
import webNav from '@/components/webNav.vue' // web 导航
import webFooter from '@/assembly/web/webFooter.vue' // web 底部
import up from '@/components/Backtotop.vue'
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: '',
      equipment: '',
      only: true,
      showFooter: true,
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    if (this.w <= 750) {
      this.equipment = 'web'
    } else {
      this.equipment = 'pc'
    }
    document.title = '质量和信息安全保障'
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth
        if (this.w <= 750) {
          this.equipment = 'web'
        } else {
          this.equipment = 'pc'
        }
      })()
    }
  },
}
</script>

<style scoped>
.xx_info {
  min-height: 100vh;
  background: #000;
}
.xx_info .pc {
  background-image: linear-gradient(#fff, #000 15%);
}
.xx_info .pc .header {
  background-color: rgba(0, 0, 0, 0.6);
  min-height: 7.08rem;
  padding-top: 3.23rem;
}
.xx_info .pc .header .bg-img {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
}
.xx_info .pc .header .logo-img {
  position: absolute;
  right: 0.75rem;
  top: 2.86rem;
  width: 0.38rem;
}
.xx_info .pc .header .logo-img2 {
  position: absolute;
  right: 20px;
  top: 2.3rem;
  width: 60%;
  display: none;
}
.xx_info .pc .header .heng {
  width: 1.66rem;
  height: 0.03rem;
  background: #00b3d6;
  margin-top: 0.24rem;
  margin-bottom: 0.5rem;
  margin-left: 1.35rem;
}
.xx_info .pc .header h5 {
  font-size: 0.4rem;
  color: #fff;
  font-weight: 700;
  font-family: Microsoft YaHe;
  margin-left: 1.35rem;
}
.xx_info .pc .header .title {
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.38rem;
  font-family: Microsoft YaHe;
  margin-left: 1.35rem;
  margin-right: 1.47rem;
}

.xx_one {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.5rem 0;
}
.xx_one img {
  width: auto;
  max-width: 60%;
  max-height: 550px;
  margin: 0.5rem;
}
.footer {
  position: relative;
  background: #000;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  /* 平板 */
}
@media (max-width: 767px) {
  /* 手机 */
  .xx_info .pc .header {
    padding-top: 7.23rem;
  }
  .xx_info .pc .header .logo-img2 {
    display: block;
    top: 5rem;
  }
  .xx_info .pc .header .logo-img {
    display: none;
    top: 1rem;
  }
  .xx_info .pc .header h5 {
    margin-left: 40px;
    font-size: 20px;
    font-weight: normal;
  }
  .xx_info .pc .header .heng {
    margin-left: 40px;
    width: 100px;
    height: 2px;
    margin-top: 10px;
    margin-bottom: 0.3rem;
  }
  .xx_info .pc .header .title {
    margin-left: 40px;
    margin-right: 30px;
    font-size: 12px;
    line-height: 22px;
  }
  .xx_one {
    padding: 20px 0;
  }
}
</style>
